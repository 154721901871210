.dsmovie-score-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dsmovie-score-value {
  margin: 0;
  color: #FFBB3A;
  font-size: 16px;
  font-weight: 700;
}

.dsmovie-score-count {
  font-size: 12px;
  color: #989898;
  margin: 4px 0 10px 0;
}