header {
  background-color: var(--color-primary);
  height: 60px;
  display: flex;
  align-items: center;
}

.dsmovie-nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.dsmovie-nav-content h1 {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
}

.dsmovie-contact-container {
  display: flex;
  align-items: center;
}

.dsmovie-contact-link {
  margin: 0 0 0 10px;
  font-size: 12px;
}